.foot {
    background-color: #11181F;
    .footer-font {
        font-family: 'Literata', sans-serif;
        color: white;
    }
    .logo {
        max-width: 30px;
        height:auto;
        
    }
    
}