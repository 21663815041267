@import "./variables";

.header {
     padding: 70px $space-l-3xl;
     .headerfont {
          font-family: 'Literata', sans-serif;
     }
     p {
          font-size: 20px;
          color: #474747;
     }
}