@import "./variables";


.home {
    padding: 2px $space-l-3xl;

    .luxury-rooms {

        .accomodations-list {
            &>div {

                display: grid;
                grid-template-columns: auto;
                column-gap: 15px;
                grid-template-rows: auto auto auto auto;
            }
        }


        padding-top: 2rem;

        .lux-head {
            padding-bottom: 2rem;
        }

        .rooms-list {

            .rooms-link-item {
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: column;
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 30%);
                transition: 0.3s all;

                h2 {
                    position: absolute;
                    bottom: 2px;
                    margin: auto;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    width: fit-content;
                    transition: 0.3s all;
                }

                img {
                    z-index: -1;
                    display: block;
                }

                &:hover {
                    background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 50%);

                    h2 {
                        font-size: 18px;
                        bottom: 5px;

                    }
                }

            }
        }
    }

    .garden-top-margin {
        margin-top: 1rem;
        margin-bottom: 10rem;

        &>div {
            margin-right: 4rem;
        }
    }




    .homepage {
        font-family: 'Inter', sans-serif;
        font-size: 18px;
    }

    .interbold {
        font-family: 'Inter', sans-serif;
    }
}